



































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useParticipantStore } from '@/devicehub/apps/dhStudy/stores/participantStore';
import { useRouter } from '@/devicehub/utils/router';
import { dhStudyRouteName } from '@/devicehub/apps/dhStudy/app';

// TODO: use type definition from Buefy when added: https://github.com/buefy/buefy/issues/2057
interface BInput {
  focus: () => void;
}

export default defineComponent({
  setup() {
    const participantStore = useParticipantStore();
    const router = useRouter();

    const input = ref<BInput | null>(null);
    const participant_id = ref('');

    onMounted(() => {
      input.value?.focus();
    });

    const save = async () => {
      if (!participant_id.value) {
        return;
      }
      const participant = await participantStore.createParticipant(
        participant_id.value,
      );
      if (participant) {
        router.push({
          name: dhStudyRouteName('participant-detail'),
          params: {
            id: participant.participant_id as string,
          },
        });
      }
    };

    return { participantStore, router, participant_id, save, input };
  },
});
